import "../styles/commonStyleSheet.scss"
import "react-multi-carousel/lib/styles.css"
import { Alert } from "@material-ui/lab"
import { Link, navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Snackbar, Typography, Grid, Button } from "@material-ui/core"
import { useSelector } from "react-redux"
import Axios from "axios"
import { BASE_URL } from "../config/api"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"
import Layout from "../components/Layout"
import LocalizedLink from "../components/LocalizedLink"
import OrderCompleted from "../images/OrderCompleted.png"
import queryString from "query-string"
import React, { useCallback, useEffect, useState } from "react"
import { SEO } from "../components/SEO"

const useStyles = makeStyles(theme => ({
  media: {
    height: 376,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  h3Style: {
    textAlign: "center",
    marginTop: "10vh",
    color: "#010204",
    fontFamily: "Clearface",
    fontSize: "80px",
    letterSpacing: "-0.4px",
  },
  h6Style: {
    textAlign: "center",
    marginTop: "5vh",
    color: "#010204",
    fontFamily: "Mabry Pro",
    fontSize: "24px",
    fontWeight: "300",
  },
  dflex: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
}))

const OrderComplete = props => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const classes = useStyles()
  const [alert, setAlert] = useState({
    status: "error",
    message: "Something went wrong",
    show: false,
  })
  const [params, setParams] = useState({})

  const confirmBooking = useCallback(async details => {
    debugger
    try {
      //Connect to the API and send the tour details to update the database
      Axios.post(`${process.env.API_URL}/confirm`, {
        info: {
          tour: details?.tour,
          schedule: details?.slot,
          date: details?.date,
          limit: details?.totalPersonsLimit,
          confirmed:
            Number(details?.children) +
            Number(details?.adults) +
            Number(details?.infants),
        },
      })
      sendEmail(details) //Call the sendEmail function to send the email
    } catch (error) {
      setAlert({
        status: "error",
        message: "Email could be send please contact us direclty",
        show: false,
      })
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (window !== undefined) {
      const queryParams = queryString.parse(window.location.search)
      setParams(queryParams)
      if (Object.keys(queryParams).length > 0) {
        if (queryParams.redirect_status === "failed") {
          setAlert({
            status: "error",
            message: "Payment failed",
            show: true,
          })
        } else if (queryParams.redirect_status === "succeeded") {
          setAlert({
            status: "success",
            message: "Payment complete",
            show: true,
          })
          confirmBooking(queryParams) //Call the confirmBooking function to update the database
        }
      }
    }
  }, [confirmBooking])

  const sendEmail = emailFields => {
    try {
      return Axios.post(BASE_URL + "/email", {
        subject: "Tour confirmation",
        html: JSON.stringify(emailFields),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout
      isHeaderDisable={true}
      location={props.location}
      pageContext={props.pageContext}
    >
      <SEO />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.show}
        autoHideDuration={6000}
        onClose={() => {
          if (alert.status === "error") navigate("/")
        }}
      >
        <Alert
          onClose={() => {
            if (alert.status === "error") navigate("/")
          }}
          severity={alert.status}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      {params?.redirect_status === "failed" && (
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: 450,
            paddingRight: 450,
            paddingTop: 60,
            paddingBottom: 60,
          }}
          justify="center"
          className={locale === "zh" && "chinese-version"}
        >
          <Typography
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            zhutravel
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            style={{ marginTop: 20, fontFamily: "clearface" }}
          >
            Order failed!. Please try it again
            <LocalizedLink to="/"> Homepage</LocalizedLink>
          </Typography>
        </Grid>
      )}
      {params?.redirect_status === "succeeded" && (
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: 450,
            paddingRight: 450,
            paddingTop: 60,
            paddingBottom: 60,
          }}
          justify="center"
          className={locale === "zh" && "chinese-version"}
        >
          <Grid item xs={10} md={6} lg={6} className={classes.column}>
            <Typography
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              zhutravel
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              style={{ marginTop: 20, fontFamily: "clearface" }}
            >
              Order Completed!
            </Typography>
            <Typography style={{ fontSize: 12, fontFamily: "MabryProLight" }}>
              Thanks for booking with zhutravel
            </Typography>

            <Typography
              variant="h5"
              component="h5"
              style={{ marginTop: 20, fontFamily: "MabryProBold" }}
            >
              {params.tour}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                marginTop: 20,
                fontFamily: "MabryProLight",
              }}
            >
              ITENERARY
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircleChecked style={{ color: "#FF9A8B" }} />
              <Typography
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontFamily: "MabryProRegular",
                }}
              >
                {params.date && params.date}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircleChecked style={{ color: "#FF9A8B" }} />
              <Typography
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontFamily: "MabryProRegular",
                }}
              >
                {params.slot && params.slot}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircleChecked style={{ color: "#FF9A8B" }} />
              <Typography
                style={{
                  fontSize: 14,
                  marginLeft: 5,
                  fontFamily: "MabryProRegular",
                }}
              >
                {params.adults && `${params.adults} Adults `}
                {params.children && `${params.children} Children `}
                {params.infants && `${params.infants} Infants.`}
              </Typography>
            </div>
            <Typography
              style={{
                marginTop: 20,
                fontSize: 12,
                fontFamily: "MabryProLight",
              }}
            >
              Total
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ flexDirection: "row", display: "flex" }}>
                <Typography
                  style={{ fontSize: 16, fontFamily: "MabryProBold" }}
                >
                  {params.total && params.total}
                </Typography>
                <Typography
                  style={{
                    fontSize: 12,
                    marginLeft: 5,
                    marginTop: 5,
                    fontFamily: "MabryProLight",
                  }}
                >
                  EUR
                </Typography>
              </Typography>
            </div>
            <Button
              variant="outlined"
              component={Link}
              to="/"
              color="white"
              style={{
                marginTop: 30,
                color: "black",
                fontFamily: "MabryProRegular",
              }}
            >
              <Typography style={{ fontSize: 12 }}>
                {" "}
                Back to zhutravel
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={10} md={6} lg={6}>
            <img
              alt=""
              style={{ width: "100%", height: 520, marginLeft: 10 }}
              src={OrderCompleted}
            />
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

export default OrderComplete
